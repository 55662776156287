@import '../../styles/variables';

.footer-nav {
  padding: calc(var(--spacing-unit) * 2) 0;
  &.specials-tray-padding {
    padding-bottom: 50px;
  }
  .logo {
    text-align: center;
    > svg > path {
      fill: var(--white);
    }
  }
  .footer-links {
    padding: 0 100px;
    margin-top: calc(var(--spacing-unit) * 2);
    .curaleaf-accordion .footer-link-list {
      margin: 0;
      list-style: none;
      padding-left: 0;
      li:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }
  }
  .footer-disclaimer {
    margin: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2) 0
      calc(var(--spacing-unit) * 2);
    > div {
      max-width: 734px;
      margin: 0 auto;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .corp-social-media {
    max-width: 340px;
    margin: calc(var(--spacing-unit) * 2) auto;
  }
  @include media-breakpoint-down(xs) {
    padding-top: 48px;
    .footer-links {
      padding: 0;
    }
    .footer-disclaimer {
      margin: calc(var(--spacing-unit) * 2) 24px 0 24px;
    }
  }
}

.legal-modal {
  .p-dialog-footer {
    padding: var(--spacing-unit);
  }
}
