@import '../../styles/variables';

@mixin mobile-styles {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  .inbv-info,
  .qpd-info {
    flex: 0 0 auto;
  }
  .inbv-info > .nbv {
    .price-info {
      text-align: left;
      .price {
        flex-direction: row;
      }
    }
  }
  .qpd-info {
    flex-direction: column-reverse;
    align-items: flex-start;
    .qd {
      gap: 10px;
      flex-direction: row-reverse;
    }
    .delete {
      width: 130px;
      margin: 0;
      text-align: center;
    }
  }
  .pv-info {
    width: 100%;
    align-items: flex-end;
  }
  .variants-container {
    max-width: unset;
    width: 100%;
    justify-content: flex-start;
    margin-top: var(--spacing-unit);
  }
}

.product-list-tile {
  position: relative;
  min-height: 160px;
  height: 100%;
  border: 1px solid var(--secondary-primary);
  border-radius: 16px;
  &.popup {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
  .spinner-icon {
    background-color: transparent;
    width: 100%;
    .loading-spinner {
      border-color: var(--primary);
      border-right-color: transparent;
    }
  }
  .info-container {
    width: 100%;
    gap: calc(var(--spacing-unit) * 2);
    .inbv-info {
      gap: 10px;
      .image-container {
        &:hover {
          transform: scale(1.05);
        }
        min-width: 130px;
        position: relative;
        height: 130px;
        width: 130px;
      }
      .nbv {
        gap: 8px;
      }
    }
    .qpd-info {
      .price-info {
        width: 100%;
        padding-inline: 59px 22px;
        .price {
          max-width: 65px;
          min-width: 56px;
          width: 100%;
          display: flex;
          flex-direction: column;
          text-align: left;
          .old-price {
            margin: unset;
          }
        }
      }
    }
    .pv-info {
      position: relative;
      .variant-button {
        min-width: 88px;
        max-width: 105px;
        width: 100%;
        padding: var(--spacing-unit);
        border-radius: 4px;
        border: 1px solid var(--secondary-primary);
        &[data-has-variant='true'] {
          gap: 12px;
          height: 80px;
        }
      }
      .quantity-tag {
        z-index: 1;
        position: absolute;
        width: 28px;
        height: 28px;
        top: -8px;
        right: -8px;
        text-align: center;
        border-radius: 50%;
        background-color: var(--white);
        border: 1px solid var(--secondary-primary);
        padding-top: 3px;
      }
    }
    .variants-container {
      &[data-multi-line='true'] {
        max-width: 645px;
        min-width: 300px;
        justify-content: flex-start;
        column-gap: var(--spacing-unit);
        @include media-breakpoint-down(md) {
          max-width: unset;
          width: 100%;
        }
      }
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 20px;
      padding: var(--spacing-unit) var(--spacing-unit) var(--spacing-unit) 0;
      @include media-breakpoint-down(md) {
        max-width: unset;
        width: 100%;
      }
      .variant-btn {
        position: relative;
        min-width: 88px;
        max-width: fit-content;
        width: 100%;
        padding: var(--spacing-unit);
        border-radius: 4px;
        border: 1px solid var(--secondary-primary);
        &[data-has-variant='true'] {
          gap: 12px;
          height: 80px;
        }
        .spinner-icon {
          position: absolute;
          width: 30px;
          height: 30px;
          top: -14px;
          right: -14px;
        }
        .plus-icon {
          z-index: 1;
          position: absolute;
          width: 30px;
          height: 30px;
          top: -14px;
          right: -14px;
          text-align: center;
          border-radius: 50%;
          background-color: var(--white);
        }
        &:hover {
          .plus-icon svg > g {
            fill: var(--light-brown);
          }
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    min-height: 240px;
    height: 100%;
    .info-container {
      @include mobile-styles;
    }
  }
  [data-order-history='true'] {
    @include mobile-styles;
  }
  // .order-history-tile {
  //   @include mobile-styles;
  // }
}
