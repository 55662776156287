.quantity-select {
  position: relative;
  height: 56px;
  width: 120px;
  border: 1px solid var(--secondary-primary);
  border-radius: 4px;
  padding: 10px var(--spacing-unit);
  input {
    position: relative;
    flex: 1 1 auto;
    min-width: 0;
    width: 36px;
    max-width: 36px;
    border: none;
    text-align: center;
    -webkit-appearance: textfield;
    appearance: textfield;
    transition: none;
    margin: 0 6px;
    padding: 0;
  } /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .quantity-btn {
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:first-of-type {
      order: -1;
    }
    > svg {
      width: 20px;
    }
    .visually-hidden,
    .visually-hidden-focusable:not(:focus):not(:focus-within) {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
    &.disabled {
      cursor: not-allowed;
      > svg > path {
        fill: var(--secondary-primary);
      }
    }
    &:hover:not(.disabled) {
      > svg > path {
        fill: var(--input-hover) !important; //override inline svg styles
      }
    }
  }
}
