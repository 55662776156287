@import '../../../styles/variables';

.store-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, var(--max-value)));
  grid-gap: 25px;
  &[data-loading='true'] {
    position: relative;
    margin: calc(var(--spacing-unit) * 4) 0;
  }
  &[data-multiple-stores='true'] {
    --max-value: 1fr;
    justify-content: center;
  }
  &[data-multiple-stores='false'] {
    --max-value: 420px;
    @include media-breakpoint-down(md) {
      --max-value: 1fr;
    }
  }
  &[data-split-hours='true'] {
    .store-card > .store-info > .todays-hours {
      height: 37px;
      @include media-breakpoint-down(sm) {
        height: unset;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    grid-gap: 10px;
  }
}
