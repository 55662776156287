@import '../../../styles/animations';
@import '../../../styles/variables';

.cart-button {
  color: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  svg {
    margin-right: 8px;
  }
  &.primary {
    color: var(--white);
    background-color: var(--primary);
  }
  &.secondary {
    color: var(--primary);
    background-color: white;
  }
  &:hover:not(:disabled),
  &:active {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
    box-shadow: var(--outline);
  }
  .spinner-icon {
    width: 88px;
    margin: 0 auto;
    .spinner-border {
      height: 16px;
      width: 16px;
      border-width: 2px;
    }
    .text-secondary {
      color: var(--white) !important;
    }
  }
  &[data-button-disabled='true'],
  &.xs {
    border: 2px solid var(--primary);
    border-radius: 4px;
    height: 33px;
    padding: 8px var(--spacing-unit);
  }
  &.sm {
    border: 2px solid var(--primary);
    border-radius: 8px;
    height: 40px;
    padding: 8px 24px;
  }
  &.lg {
    border: 4px solid var(--primary);
    border-radius: 16px;
    height: 56px;
    padding: var(--spacing-unit) calc(var(--spacing-unit) * 2);
  }
  &:disabled {
    pointer-events: none;
    background: var(--disabled);
    border: 4px solid var(--disabled);
    color: var(--white);
    cursor: default;
  }
}

.loading-spinner {
  background-color: transparent;
  margin: 0 auto;
  border: 2px solid var(--primary);
  border-right-color: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
