.embeddable-form-curaleaf {
  .form-row {
    gap: var(--spacing-unit);
    .form-col {
      width: 50%;
    }
  }
  .buttons {
    display: flex;
    gap: var(--spacing-unit);
    .cta-button > button {
      width: 100%;
    }
  }
}
