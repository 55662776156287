.clear-dispensary-modal {
  min-width: 375px;
  max-width: 90vw;
  .p-dialog-content {
    padding: var(--spacing-unit);
  }
  .p-dialog-header {
    padding: var(--spacing-unit) var(--spacing-unit) 0 var(--spacing-unit);
  }
  .p-dialog-footer {
    padding: 0 var(--spacing-unit) var(--spacing-unit) var(--spacing-unit);
    .cta-button-tertiary {
      margin-right: 5px;
      color: var(--white);
      background-color: var(--error-dark);
      border-color: var(--error-dark);
    }
    .btn {
      width: 100%;
    }
  }
}

.clear-dispensary-button {
  background-color: transparent;
  border: none;
  svg > path {
    fill: var(--white);
  }
}
