.curaleaf-accordion {
  .curaleaf-accordion-header {
    .p-accordion-header-link {
      flex-direction: row-reverse;
      justify-content: space-between;
      background: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid var(--secondary-primary);
    }
    .up {
      transform: rotate(-180deg);
    }
  }
  .curaleaf-accordion-content {
    .p-accordion-content {
      padding: 24px 0;
      border: none;
      color: inherit;
      border-radius: 0;
      background: transparent;
    }
  }
  &.primary {
    .curaleaf-accordion-header {
      .p-accordion-header-link {
        padding: var(--spacing-unit) 0;
      }
    }
  }
  &.secondary {
    .curaleaf-accordion-header {
      .p-accordion-header-link {
        padding: var(--spacing-unit) 24px;
      }
    }
    .curaleaf-accordion-content {
      .p-accordion-content {
        padding: 24px;
      }
    }
  }
  &.light {
    .accordion-arrow > svg > path {
      stroke: var(--primary);
    }
  }
}
